<template>
  <div>
    <v-main>
      <slot :user="currentUser"> </slot>
    </v-main>
    <v-footer v-if="$route.path !== '/room'" light absolute app padless>
      <v-card class="flex" color="white" flat tile>
        <v-divider></v-divider>
        <v-card-text class="py-2  text-center">
          <v-btn
            to="/privacypolicy"
            style="text-transform: capitalize;"
            small
            text
            >Privacy Policy</v-btn
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: 'PublicLayout',
  props: ['user'],
  data() {
    return {
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    }
  }
}
</script>
